import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import '../styles/Airdrop.css';

// Define token types
const TOKEN_TYPES = {
  BASE_ERC20: 'baseERC20',
  ETH_ERC20: 'ethERC20',
  SHY_ETH_ERC20: 'shyEthERC20',
  SHY_BASE_ERC20: 'shyBaseERC20'
};

// Define token addresses
const TOKEN_ADDRESSES = {
  [TOKEN_TYPES.SHY_ETH_ERC20]: '0xea2D0efF4EF912505a929F6F5a0c9ae002ACa214',
  [TOKEN_TYPES.SHY_BASE_ERC20]: '0x9Ad6a48E4E1a69079d703A0125863eBa96B27879'
};

/**
 * Airdrop component for token distribution
 * @param {Object} props - Component props
 * @param {ethers.providers.Web3Provider} props.provider - Ethereum provider
 * @param {string} props.account - User's account address
 * @param {number} props.networkId - Current network ID
 */
function Airdrop({ provider, account, networkId }) {
  // State variables
  const [tokenType, setTokenType] = useState('');
  const [contractAddress, setContractAddress] = useState('');
  const [recipients, setRecipients] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [transactionQueue, setTransactionQueue] = useState([]);
  const [currentTransactionIndex, setCurrentTransactionIndex] = useState(0);

  // Effect to update contract address when token type changes
  useEffect(() => {
    if (tokenType === TOKEN_TYPES.SHY_ETH_ERC20 || tokenType === TOKEN_TYPES.SHY_BASE_ERC20) {
      setContractAddress(TOKEN_ADDRESSES[tokenType]);
    } else {
      setContractAddress('');
    }
  }, [tokenType]);

  /**
   * Queue transactions for airdrop
   */
  const queueTransactions = async () => {
    if (!ethers.utils.isAddress(contractAddress)) {
      setError('Invalid contract address');
      return;
    }

    const recipientList = recipients.split('\n').map(addr => addr.trim());
    if (recipientList.some(addr => !ethers.utils.isAddress(addr))) {
      setError('One or more recipient addresses are invalid');
      return;
    }

    if (isNaN(amount) || parseFloat(amount) <= 0) {
      setError('Invalid amount');
      return;
    }

    setLoading(true);
    setError(null);
    setResult(null);

    try {
      const queue = recipientList.map(recipient => ({
        recipient,
        amount: ethers.utils.parseUnits(amount, 18),
        status: 'pending'
      }));
    
      setTransactionQueue(queue);
      setCurrentTransactionIndex(0);
    } catch (error) {
      console.error('Transaction queue error:', error);
      setError('Failed to queue transactions: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Process the next transaction in the queue
   */
  const processNextTransaction = async () => {
    if (currentTransactionIndex >= transactionQueue.length) {
      setResult(transactionQueue);
      return;
    }

    const transaction = transactionQueue[currentTransactionIndex];
    setLoading(true);

    try {
      const signer = provider.getSigner(account);
      const tokenContract = new ethers.Contract(
        contractAddress,
        ['function transfer(address to, uint256 amount) returns (bool)'],
        signer
      );

      const tx = await tokenContract.transfer(transaction.recipient, transaction.amount);
      await tx.wait();

      const updatedQueue = [...transactionQueue];
      updatedQueue[currentTransactionIndex].status = 'success';
      updatedQueue[currentTransactionIndex].txHash = tx.hash;
      setTransactionQueue(updatedQueue);

      setCurrentTransactionIndex(prevIndex => prevIndex + 1);
    } catch (error) {
      console.error('Transaction error:', error);
      const updatedQueue = [...transactionQueue];
      updatedQueue[currentTransactionIndex].status = 'failed';
      updatedQueue[currentTransactionIndex].error = error.message;
      setTransactionQueue(updatedQueue);

      setCurrentTransactionIndex(prevIndex => prevIndex + 1);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="airdrop">
      <h2>Airdrop Tokens</h2>
      <div className="form-group">
        <select value={tokenType} onChange={(e) => setTokenType(e.target.value)}>
          <option value="">Select Token Type</option>
          <option value={TOKEN_TYPES.BASE_ERC20}>Base ERC20</option>
          <option value={TOKEN_TYPES.ETH_ERC20}>Eth ERC20</option>
          <option value={TOKEN_TYPES.SHY_ETH_ERC20}>SHY Eth ERC20</option>
          <option value={TOKEN_TYPES.SHY_BASE_ERC20}>SHY Base ERC20</option>
        </select>
      </div>
      {(tokenType === TOKEN_TYPES.BASE_ERC20 || tokenType === TOKEN_TYPES.ETH_ERC20) && (
        <div className="form-group">
          <input
            type="text"
            value={contractAddress}
            onChange={(e) => setContractAddress(e.target.value)}
            placeholder="Contract Address"
          />
        </div>
      )}
      <div className="form-group">
        <textarea
          value={recipients}
          onChange={(e) => setRecipients(e.target.value)}
          placeholder="Enter recipient addresses, one per line"
        />
      </div>
      <div className="form-group">
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Amount per recipient"
        />
      </div>
      {transactionQueue.length === 0 ? (
        <button onClick={queueTransactions} disabled={loading || !account}>
          {loading ? 'Processing...' : 'Queue Transactions'}
        </button>
      ) : (
        <button onClick={processNextTransaction} disabled={loading || currentTransactionIndex >= transactionQueue.length}>
          {loading ? 'Processing...' : 'Confirm Next Transaction'}
        </button>
      )}
      {error && <p className="error">{error}</p>}
      {result && (
        <div className="airdrop-result">
          <h3>Airdrop Results</h3>
          <table>
            <thead>
              <tr>
                <th>Recipient</th>
                <th>Status</th>
                <th>Transaction Hash / Error</th>
              </tr>
            </thead>
            <tbody>
              {result.map((item, index) => (
                <tr key={index}>
                  <td>{item.recipient}</td>
                  <td>{item.status}</td>
                  <td>{item.status === 'success' ? item.txHash : item.error}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Airdrop;
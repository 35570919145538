import React, { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import { Alchemy, Network } from 'alchemy-sdk';
import '../styles/TokenGate.css';

// Token addresses for different networks
const TOKEN_ADDRESSES = {
  SHY_ETH: '0xea2D0efF4EF912505a929F6F5a0c9ae002ACa214',
  SHY_BASE: '0x9Ad6a48E4E1a69079d703A0125863eBa96B27879',
  G0HST_ETH: '0x4d96c1D6f4469b58118c710290BFA40bB4848Eb8',
  G0HST_BASE: '0x7B0bd5CB3478BeEa08fc5d93637f3AC840F05e2e'
};

// Alchemy networks
const NETWORKS = {
  ETH: Network.ETH_MAINNET,
  BASE: Network.BASE_MAINNET
};

/**
 * TokenGate Component
 * Checks if a user has the required tokens to access the application
 */
function TokenGate({ isWalletConnected, account, provider, setHasAccess }) {
  const [isChecking, setIsChecking] = useState(false);
  const [checkResults, setCheckResults] = useState({});
  const [overallResult, setOverallResult] = useState(null);
  const [error, setError] = useState(null);

  /**
   * Check ERC20 token balance
   * @param {string} tokenAddress - Token contract address
   * @param {string} userAddress - User's address
   * @param {Network} network - Alchemy network
   * @returns {Promise<boolean>} Whether the user has a non-zero balance
   */
  const checkERC20Balance = useCallback(async (tokenAddress, userAddress, network) => {
    const alchemy = new Alchemy({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY, network });
    const balance = await alchemy.core.getTokenBalances(userAddress, [tokenAddress]);
    return ethers.BigNumber.from(balance.tokenBalances[0].tokenBalance).gt(0);
  }, []);

  /**
   * Check ERC721 token balance
   * @param {string} tokenAddress - Token contract address
   * @param {string} userAddress - User's address
   * @param {Network} network - Alchemy network
   * @returns {Promise<boolean>} Whether the user has any NFTs
   */
  const checkERC721Balance = useCallback(async (tokenAddress, userAddress, network) => {
    const alchemy = new Alchemy({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY, network });
    const nfts = await alchemy.nft.getNftsForOwner(userAddress, { contractAddresses: [tokenAddress] });
    return nfts.totalCount > 0;
  }, []);

  /**
   * Check g0hst Fan Tokens on BASE
   * @param {string} userAddress - User's address
   * @returns {Promise<boolean>} Whether the user has any g0hst Fan Tokens
   */
  const checkG0hstFanTokens = useCallback(async (userAddress) => {
    const alchemy = new Alchemy({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY, network: NETWORKS.BASE });
    const nfts = await alchemy.nft.getNftsForOwner(userAddress);
    return nfts.ownedNfts.some(nft => 
      nft.contract.name === "g0hst" && nft.contract.symbol === "fid:842363"
    );
  }, []);

  /**
   * Check all required tokens
   */
  const checkTokens = useCallback(async () => {
    if (!account) {
      setError("No account connected. Please connect your wallet.");
      return;
    }

    setIsChecking(true);
    setError(null);
    const results = {};

    try {
      // Parallel execution of token checks for better performance
      const [shyEth, shyBase, g0hstsEth, g0hstsBase, g0hstFanTokens] = await Promise.all([
        checkERC20Balance(TOKEN_ADDRESSES.SHY_ETH, account, NETWORKS.ETH),
        checkERC20Balance(TOKEN_ADDRESSES.SHY_BASE, account, NETWORKS.BASE),
        checkERC721Balance(TOKEN_ADDRESSES.G0HST_ETH, account, NETWORKS.ETH),
        checkERC721Balance(TOKEN_ADDRESSES.G0HST_BASE, account, NETWORKS.BASE),
        checkG0hstFanTokens(account)
      ]);

      results.shyEth = shyEth;
      results.shyBase = shyBase;
      results.g0hstsEth = g0hstsEth;
      results.g0hstsBase = g0hstsBase;
      results.g0hstFanTokens = g0hstFanTokens;

      setCheckResults(results);
      const hasAccess = Object.values(results).some(result => result);
      setOverallResult(hasAccess);
      setHasAccess(hasAccess);
    } catch (error) {
      console.error('Error checking tokens:', error);
      setError("An error occurred while checking your tokens. Please try again.");
    } finally {
      setIsChecking(false);
    }
}, [account, checkERC20Balance, checkERC721Balance, checkG0hstFanTokens, setHasAccess]);

// Effect to trigger token check when wallet is connected
useEffect(() => {
  if (isWalletConnected && account) {
    checkTokens();
  }
}, [isWalletConnected, account, checkTokens]);

/**
 * Render the result of a token check
 * @param {string} label - Label for the token check
 * @param {boolean} result - Result of the token check
 * @returns {JSX.Element} Rendered result
 */
const renderCheckResult = (label, result) => (
  <li className={`check-result ${result !== undefined ? (result ? 'success' : 'failure') : ''}`}>
    {label}: {result !== undefined ? (result ? '✅' : '❌') : '⏳'}
  </li>
);

return (
  <div className="token-gate">
    <h1>Welcome to omniBoard</h1>
    <p>To access the dashboard, you need to hold SHY tokens, g0hsts NFTs, or g0hst Fan Tokens.</p>
    
    {isWalletConnected ? (
      <div className="token-check-results">
        <h2>Checking Token Holdings</h2>
        {isChecking ? (
          <div className="loading">Checking your tokens...</div>
        ) : error ? (
          <div className="error">{error}</div>
        ) : (
          <>
            <ul>
              {renderCheckResult('SHY ETH Tokens', checkResults.shyEth)}
              {renderCheckResult('SHY BASE Tokens', checkResults.shyBase)}
              {renderCheckResult('g0hsts NFTs (ETH)', checkResults.g0hstsEth)}
              {renderCheckResult('g0hsts NFTs (BASE)', checkResults.g0hstsBase)}
              {renderCheckResult('g0hst Fan Tokens (BASE)', checkResults.g0hstFanTokens)}
            </ul>
            {overallResult !== null && (
              <div className={`overall-result ${overallResult ? 'success' : 'failure'}`}>
                {overallResult ? (
                  <>
                    <p>Access Granted! 🎉</p>
                    <button onClick={() => setHasAccess(true)} className="continue-button">Continue to Dashboard</button>
                  </>
                ) : (
                  <p>
                    Access Denied. Please purchase SHY tokens, g0hsts NFTs, or g0hst Fan Tokens to access the dashboard.
                  </p>
                )}
              </div>
            )}
          </>
        )}
      </div>
    ) : (
      <p>Connect your wallet using the button in the header to check for access.</p>
    )}
  </div>
);
}

export default TokenGate;
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Alchemy, Network } from 'alchemy-sdk';
import { ethers } from 'ethers';
import '../styles/NFTCollection.css';

const G0HST_CONTRACT = '0x7b0bd5cb3478beea08fc5d93637f3ac840f05e2e';
const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;
const OPENSEA_API_KEY = process.env.REACT_APP_OPENSEA_API_KEY;

const NETWORKS = {
  ETH: { 
    name: 'Ethereum', 
    alchemyNetwork: Network.ETH_MAINNET,
    scanUrl: 'https://etherscan.io/token/'
  },
  BASE: { 
    name: 'Base', 
    alchemyNetwork: Network.BASE_MAINNET,
    scanUrl: 'https://basescan.io/token/'
  }
};

/**
 * NFTCollection Component
 * Displays and filters NFTs from a collection
 */
function NFTCollection({ provider, account, networkId }) {
  const [network, setNetwork] = useState('BASE');
  const [contractAddress, setContractAddress] = useState(G0HST_CONTRACT);
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [collectionMetadata, setCollectionMetadata] = useState(null);
  const [traits, setTraits] = useState({});
  const [filters, setFilters] = useState({});

  /**
   * Fetch NFT data from Alchemy API
   */
  const fetchData = useCallback(async () => {
    if (!contractAddress || !ethers.utils.isAddress(contractAddress)) {
      setError('Invalid contract address');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const config = {
        apiKey: ALCHEMY_API_KEY,
        network: NETWORKS[network].alchemyNetwork,
      };
      const alchemy = new Alchemy(config);

      const [alchemyMetadata, openseaMetadata] = await Promise.all([
        alchemy.nft.getContractMetadata(contractAddress),
        fetchOpenseaMetadata(contractAddress)
      ]);

      // Combine Alchemy and OpenSea metadata
      const combinedMetadata = {
        ...alchemyMetadata,
        ...openseaMetadata,
        floorPrice: openseaMetadata.stats?.floor_price,
        totalVolume: openseaMetadata.stats?.total_volume,
        owners: openseaMetadata.stats?.num_owners
      };

      setCollectionMetadata(combinedMetadata);

      const nftData = await alchemy.nft.getNftsForContract(contractAddress, {
        pageSize: 100,
        omitMetadata: false,
      });

      setNfts(nftData.nfts);
      extractTraits(nftData.nfts);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data: ' + error.message);
    } finally {
      setLoading(false);
    }
  }, [contractAddress, network]);

  /**
   * Fetch metadata from OpenSea API
   * @param {string} contractAddress - The NFT contract address
   * @returns {Promise<Object>} OpenSea metadata
   */
  const fetchOpenseaMetadata = async (contractAddress) => {
    try {
      const response = await axios.get(`https://api.opensea.io/api/v1/asset_contract/${contractAddress}`, {
        headers: { 'X-API-KEY': OPENSEA_API_KEY }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching OpenSea metadata:', error);
      return {};
    }
  };

  /**
   * Extract traits from NFT metadata
   * @param {Array} nfts - Array of NFT objects
   */
  const extractTraits = (nfts) => {
    const traitMap = {};
    nfts.forEach(nft => {
      nft.rawMetadata.attributes.forEach(attr => {
        if (!traitMap[attr.trait_type]) {
          traitMap[attr.trait_type] = new Set();
        }
        traitMap[attr.trait_type].add(attr.value);
      });
    });

    // Convert Sets to Arrays
    for (const trait in traitMap) {
      traitMap[trait] = Array.from(traitMap[trait]);
    }

    setTraits(traitMap);
  };

  /**
   * Handle network change
   * @param {Event} e - Change event
   */
  const handleNetworkChange = (e) => {
    setNetwork(e.target.value);
    setContractAddress(e.target.value === 'BASE' ? G0HST_CONTRACT : '');
  };

  /**
   * Handle filter change
   * @param {string} traitType - The trait type
   * @param {string} value - The selected value
   */
  const handleFilterChange = (traitType, value) => {
    setFilters(prev => ({
      ...prev,
      [traitType]: value === 'All' ? null : value
    }));
  };

  // Filter NFTs based on selected traits
  const filteredNFTs = nfts.filter(nft => {
    return Object.entries(filters).every(([traitType, value]) => {
      if (!value) return true; // If no filter is set for this trait
      return nft.rawMetadata.attributes.some(attr => 
        attr.trait_type === traitType && attr.value === value
      );
    });
  });

  // Fetch data when component mounts or when contractAddress/network changes
  useEffect(() => {
    if (contractAddress && ethers.utils.isAddress(contractAddress)) {
      fetchData();
    }
  }, [contractAddress, network, fetchData]);

  return (
    <div className="nft-collection">
      <h2>NFT Collection Explorer</h2>
      
      <div className="form-group">
        <label htmlFor="network">Select Network:</label>
        <select 
          id="network"
          value={network} 
          onChange={handleNetworkChange}
          disabled={loading}
          className="outlined-select"
        >
          <option value="BASE">Base</option>
          <option value="ETH">Ethereum</option>
        </select>
      </div>
      
      <div className="form-group">
        <label htmlFor="contract-address">Contract Address:</label>
        <input
          id="contract-address"
          type="text"
          value={contractAddress}
          onChange={(e) => setContractAddress(e.target.value)}
          placeholder="Enter contract address"
          disabled={loading}
          className="outlined-input"
        />
      </div>
      
      <button onClick={fetchData} disabled={loading || !contractAddress}>
        {loading ? 'Loading...' : 'Fetch Data'}
      </button>
      
      {error && <p className="error">{error}</p>}
      
      {collectionMetadata && (
        <div className="collection-info">
          <h3>Collection Information</h3>
          <p><strong>Name:</strong> {collectionMetadata.name}</p>
          <p><strong>Symbol:</strong> {collectionMetadata.symbol}</p>
          <p><strong>Total Supply:</strong> {collectionMetadata.totalSupply}</p>
          {collectionMetadata.floorPrice && <p><strong>Floor Price:</strong> {collectionMetadata.floorPrice} ETH</p>}
          {collectionMetadata.totalVolume && <p><strong>Total Volume:</strong> {collectionMetadata.totalVolume} ETH</p>}
          {collectionMetadata.owners && <p><strong>Number of Owners:</strong> {collectionMetadata.owners}</p>}
          {collectionMetadata.description && <p><strong>Description:</strong> {collectionMetadata.description}</p>}
        </div>
      )}

      <div className="trait-filters">
        {Object.entries(traits).map(([traitType, values]) => (
          <div key={traitType} className="trait-filter">
            <label htmlFor={traitType}>{traitType}:</label>
            <select
              id={traitType}
              onChange={(e) => handleFilterChange(traitType, e.target.value)}
              value={filters[traitType] || 'All'}
            >
              <option value="All">All</option>
              {values.map(value => (
                <option key={value} value={value}>{value}</option>
              ))}
            </select>
          </div>
        ))}
      </div>
      
      <div className="nft-grid">
        {filteredNFTs.map((nft, index) => (
          <div key={index} className="nft-card">
            <img src={nft.media[0]?.gateway || 'placeholder-image-url'} alt={nft.title} />
            <h3>{nft.title}</h3>
            <p>Token ID: {nft.tokenId}</p>
            <a href={`${NETWORKS[network].scanUrl}${contractAddress}?a=${nft.tokenId}`} target="_blank" rel="noopener noreferrer">
              View on {network === 'BASE' ? 'Basescan' : 'Etherscan'}
            </a>
            <div className="traits">
              {nft.rawMetadata.attributes.map((attr, i) => (
                <span key={i} className="trait">
                  {attr.trait_type}: {attr.value}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NFTCollection;
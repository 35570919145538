import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ethers } from 'ethers';
import { useOnchainKit } from '@coinbase/onchainkit';
import '../styles/Header.css';

// Network names mapping
const NETWORK_NAMES = {
  1: 'Ethereum Mainnet',
  3: 'Ropsten Testnet',
  4: 'Rinkeby Testnet',
  5: 'Goerli Testnet',
  42: 'Kovan Testnet',
  56: 'Binance Smart Chain',
  137: 'Polygon Mainnet',
  80001: 'Mumbai Testnet',
  43114: 'Avalanche C-Chain',
  8453: 'Base'
};

/**
 * Header component
 * Displays the application logo, network information, theme toggle, and wallet connection status
 */
function Header({ theme, toggleTheme, onConnect }) {
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState('');
  const [networkId, setNetworkId] = useState(null);
  const { connect } = useOnchainKit();

  /**
   * Truncate Ethereum address for display
   * @param {string} addr - Full Ethereum address
   * @returns {string} Truncated address
   */
  const truncateAddress = (addr) => {
    if (!addr) return '';
    return `${addr.slice(0, 6)}...${addr.slice(-4)}`;
  };

  /**
   * Get network name from network ID
   * @param {number} id - Network ID
   * @returns {string} Network name
   */
  const getNetworkName = (id) => {
    return NETWORK_NAMES[id] || `Unknown Network (${id})`;
  };

  /**
   * Handle wallet connection
   */
  const handleConnect = useCallback(async () => {
    try {
      const result = await connect();
      if (result && result.accounts.length > 0) {
        const address = result.accounts[0];
        setIsConnected(true);
        setAddress(address);
        
        // Create a Web3Provider using the connected wallet
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();
        setNetworkId(network.chainId);
        
        onConnect(provider, address);
      }
    } catch (error) {
      console.error('Failed to connect wallet:', error);
    }
  }, [connect, onConnect]);

  /**
   * Handle wallet disconnection
   */
  const handleDisconnect = useCallback(() => {
    setIsConnected(false);
    setAddress('');
    setNetworkId(null);
    onConnect(null, null);
  }, [onConnect]);

  /**
   * Effect to check wallet connection status on component mount
   */
  useEffect(() => {
    const checkConnection = async () => {
      if (window.ethereum && window.ethereum.selectedAddress) {
        const address = window.ethereum.selectedAddress;
        setIsConnected(true);
        setAddress(address);
        
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();
        setNetworkId(network.chainId);
        
        onConnect(provider, address);
      }
    };

    checkConnection();
  }, [onConnect]);

  return (
    <header className="header">
      <Link to="/" className="logo">omniBoard</Link>
      <div className="header-actions">
        {/* Network indicator */}
        {isConnected && networkId && (
          <span className="network-indicator" title={`Connected to ${getNetworkName(networkId)}`}>
            {getNetworkName(networkId)}
          </span>
        )}
        
        {/* Theme toggle button */}
        <button 
          onClick={toggleTheme} 
          className="theme-toggle"
          aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} theme`}
        >
          {theme === 'light' ? '🌙' : '☀️'}
        </button>
        
        {/* Wallet connection button or account display */}
        {isConnected ? (
          <div className="wallet-info">
            <span className="account-info" title={address}>
              {truncateAddress(address)}
            </span>
            <button onClick={handleDisconnect} className="disconnect-wallet">
              Disconnect
            </button>
          </div>
        ) : (
          <button onClick={handleConnect} className="connect-wallet">
            Connect Wallet
          </button>
        )}
      </div>
    </header>
  );
}

// PropTypes for type checking
Header.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']).isRequired,
  toggleTheme: PropTypes.func.isRequired,
  onConnect: PropTypes.func.isRequired
};

export default Header;
import React from 'react';
import { Link } from 'react-router-dom';
import { FaUsers, FaParachuteBox, FaRocket } from 'react-icons/fa';
import '../styles/Dashboard.css';

/**
 * Dashboard Component
 * Displays the main dashboard with links to various features
 */
function Dashboard() {
  return (
    <div className="dashboard">
      <h1>Welcome to omniBoard</h1>
      <p>Your all-in-one dashboard for managing tokens across multiple blockchains.</p>
      
      <div className="feature-cards">
        <div className="feature-card">
          <FaUsers className="feature-icon" />
          <h2>Token Holders</h2>
          <p>View and analyze token holder information for any ERC20 token or NFT collection.</p>
          <Link to="/holders" className="feature-link">View Holders</Link>
        </div>
        
        <div className="feature-card">
          <FaParachuteBox className="feature-icon" />
          <h2>Airdrop</h2>
          <p>Easily distribute tokens to multiple addresses in one transaction.</p>
          <Link to="/airdrop" className="feature-link">Start Airdrop</Link>
        </div>
        
        <div className="feature-card">
          <FaRocket className="feature-icon" />
          <h2>Deploy Token</h2>
          <p>Create and deploy your own ERC20 token with custom parameters.</p>
          <Link to="/deploy" className="feature-link">Deploy Token</Link>
        </div>
      </div>
      
      <div className="dashboard-stats">
        <h2>Network Statistics</h2>
        <p>Total Value Locked (TVL): $X,XXX,XXX</p>
        <p>24h Trading Volume: $X,XXX,XXX</p>
        <p>Active Users: XX,XXX</p>
      </div>
    </div>
  );
}

export default Dashboard;
import React from 'react';
import '../styles/Footer.css';

/**
 * Footer component
 * Displays copyright information, disclaimer, and support details
 * @returns {React.Component} Footer component
 */
function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="disclaimer">
        <p>Disclaimer: Use this dashboard at your own risk. Always verify transactions before confirming.</p>
        <p>Support: <a href="mailto:support@domain.com">support@domain.com</a></p>
        <p>Donations: 0xc788Cf7cF990462a7484d1A1dD036bEC2AaA49Ed</p>
      </div>
      <p>&copy; {currentYear} shyguy, LLC. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ethers } from 'ethers';
import { OnchainKitProvider } from '@coinbase/onchainkit';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Dashboard from './Dashboard';
import NFTCollection from './NFTCollection';
import Airdrop from './Airdrop';
import DeployToken from './DeployToken';
import TokenGate from './TokenGate';
import '../styles/App.css';

/**
 * Main App component
 * Manages routing, global state, and renders the main layout
 */
function App() {
  // State management
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [account, setAccount] = useState(null);
  const [networkId, setNetworkId] = useState(null);
  const [theme, setTheme] = useState('dark');
  const [hasAccess, setHasAccess] = useState(false);
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  /**
   * Toggle between light and dark themes
   */
  const toggleTheme = useCallback(() => {
    setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
  }, []);

  // Apply theme to body
  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  /**
   * Initialize Web3 provider
   */
  const initWeb3 = useCallback(async () => {
    if (window.ethereum) {
      try {
        // Request account access
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(web3Provider);
        const signer = web3Provider.getSigner();
        setSigner(signer);
        const address = await signer.getAddress();
        setAccount(address);
        const network = await web3Provider.getNetwork();
        setNetworkId(network.chainId);
        setIsWalletConnected(true);
      } catch (error) {
        console.error('Failed to initialize Web3:', error);
      }
    } else {
      console.error('No Ethereum wallet detected');
    }
  }, []);

  /**
   * Handle wallet connection
   */
  const handleConnect = useCallback(async () => {
    await initWeb3();
  }, [initWeb3]);

  /**
   * Handle wallet disconnection
   */
  const handleDisconnect = useCallback(() => {
    setProvider(null);
    setSigner(null);
    setAccount(null);
    setNetworkId(null);
    setIsWalletConnected(false);
    setHasAccess(false);
  }, []);

  return (
    <OnchainKitProvider>
      <Router>
        <div className={`app-container ${theme}`}>
          <Header 
            theme={theme}
            toggleTheme={toggleTheme}
            isWalletConnected={isWalletConnected}
            account={account}
            networkId={networkId}
            onConnect={handleConnect}
            onDisconnect={handleDisconnect}
          />
          <div className="main-content-wrapper">
            {hasAccess && <Sidebar />}
            <main className="page-content">
              <Routes>
                <Route 
                  path="/" 
                  element={
                    hasAccess ? (
                      <Navigate to="/dashboard" />
                    ) : (
                      <TokenGate
                        isWalletConnected={isWalletConnected}
                        account={account}
                        provider={provider}
                        setHasAccess={setHasAccess}
                      />
                    )
                  } 
                />
                <Route path="/dashboard" element={hasAccess ? <Dashboard /> : <Navigate to="/" />} />
                <Route path="/holders" element={hasAccess ? <NFTCollection provider={provider} account={account} networkId={networkId} /> : <Navigate to="/" />} />
                <Route 
                  path="/airdrop" 
                  element={
                    hasAccess ? (
                      <Airdrop 
                        provider={provider} 
                        account={account}
                        networkId={networkId}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  } 
                />
                <Route 
                  path="/deploy" 
                  element={
                    hasAccess ? (
                      <DeployToken 
                        provider={provider} 
                        account={account}
                        networkId={networkId}
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  } 
                />
              </Routes>
            </main>
          </div>
          <Footer />
        </div>
      </Router>
    </OnchainKitProvider>
  );
}

export default App;
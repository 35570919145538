import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaUsers, FaParachuteBox, FaRocket } from 'react-icons/fa';
import '../styles/Sidebar.css';

/**
 * Sidebar component
 * Provides navigation links for the application
 * @returns {React.Component} Sidebar component
 */
function Sidebar() {
  // Function to determine the class name for NavLink
  const getLinkClassName = ({ isActive }) => 
    isActive ? "nav-link active" : "nav-link";

  return (
    <aside className="sidebar">
      <nav>
        <ul className="nav-list">
          <li className="nav-item">
            <NavLink to="/dashboard" className={getLinkClassName}>
              <FaHome className="nav-icon" />
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/holders" className={getLinkClassName}>
              <FaUsers className="nav-icon" />
              <span>NFT Collections</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/airdrop" className={getLinkClassName}>
              <FaParachuteBox className="nav-icon" />
              <span>Airdrop</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/deploy" className={getLinkClassName}>
              <FaRocket className="nav-icon" />
              <span>Deploy Token</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </aside>
  );
}

export default Sidebar;